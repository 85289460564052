import { useRouter } from 'next/router';
import { useSiteOptions } from '@/hooks';

import { Button, Flex } from '@/components/new';
import PageLink from '@/PUFComponents/base/navigation/PageLinkV2';

export default function Custom404Page() {
  const router = useRouter();
  const siteOptions = useSiteOptions();

  return (
    <div className="Page">
      <div className="Page-body error-page page-404">
        <div className="content-container">
          <h1 className="error-title">Page introuvable</h1>

          <div className="error-body">
            <p>
              Cela signifie en général que la page que vous recherchez n'existe
              pas ou plus.
            </p>
            <p>
              Si vous êtes arrivés sur cette page en cliquant sur un lien du
              site, n'hésitez pas à nous contacter en envoyant un courriel
              {siteOptions.contactEmail ? (
                <span>
                  {' '}
                  à{' '}
                  <a href={`mailto:${siteOptions.contactEmail}`}>
                    {siteOptions.contactEmail}
                  </a>{' '}
                </span>
              ) : (
                <span> </span>
              )}
              pour signaler le lien défectueux en nous indiquant la page à
              partir duquel vous êtes arrivés.
            </p>
          </div>

          <div className="error-footer">
            <p>Que souhaitez-vous faire ?</p>
            <Flex additionalClassName="actions">
              <Button onClick={router.back}>
                Retourner sur la page précédente
              </Button>

              <PageLink link={{ url: '/' }}>
                {(fullHref) => (
                  <Button isOutlined href={fullHref}>
                    Retourner sur la page d'accueil
                  </Button>
                )}
              </PageLink>
            </Flex>
          </div>
        </div>
      </div>
    </div>
  );
}
